.bookLabel {
  font-size: 14px;
  line-height: 17px;
  color: #62686E;
  display: inline-block;
  position: relative;
  padding-left: 30px;
  padding-top: 3px;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 14px;
    height: 16px;
    transform: translateY(-50%);
    background: {
      image: url("../../static/img/book.svg");
      size: contain;
      position: center;
      repeat: no-repeat;
    };
  }
}

.featuredLabel {
  padding: 3px 6px;
  border-radius: 2.57px;
  background-color: #F3ECF8;
  color: #7D3FA8;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  max-width: 120px;
  margin-top: 5px;
}
.uaefeaturedLabel {
  // padding: 3px 6px;
  border-radius: 2.57px;
  // background-color: #F3ECF8;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  // text-align: center;
  max-width: 120px;
  margin-top: 5px;
}

.shieldLabel {
  color: #62686E;
  font-size: 14px;
  line-height: 17px;
  padding-left: 27px;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 14px;
    height: 16px;
    transform: translateY(-50%);
    background: {
      image: url("../../static/img/shield.svg");
      size: contain;
      position: center;
      repeat: no-repeat;
    };
  }
}

.countLabel {
  color: #62686E;
  font-size: 14px;
  line-height: 17px;
  display: inline-block;

  .countLabelNumber {
    border-radius: 3px;
    background-color: #9658C0;
    height: 24px;
    min-width: 26px;
    font-size: 14px;
    line-height: 14px;
    padding: 3px 1px;
    color: #ffffff;
    display: inline-flex;
    align-items: center;
  }
  .uaecountLabelNumber {
    border-radius: 3px;
    height: 24px;
    min-width: 26px;
    font-size: 14px;
    line-height: 14px;
    padding: 3px 1px;
    color: #000;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
  }
}

.growthLabel {
  font-size: 14px;
  line-height: 17px;
  color: #62686E;
  display: inline-block;
  position: relative;
  padding-left: 28px;
  padding-top: 3px;
  word-break: break-word;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 16px;
    height: 16px;
    transform: translateY(-50%);
    background: {
      image: url("../../static/img/growth.svg");
      size: contain;
      position: center;
      repeat: no-repeat;
    };
  }
}

.pinLabel {
  font-size: 14px;
  line-height: 17px;
  color: #62686E;
  display: inline-block;
  position: relative;
  padding-left: 30px;
  padding-top: 3px;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 14px;
    height: 16px;
    transform: translateY(-50%);
    background: {
      image: url("../../static/img/pin.svg");
      size: contain;
      position: center;
      repeat: no-repeat;
    };
  }
}
.pinLabelTwo {
  font-size: 14px;
  line-height: 17px;
  color: #62686E;
  display: inline-block;
  position: relative;
  padding-left: 30px;
  padding-top: 3px;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 14px;
    height: 16px;
    transform: translateY(-50%);
    background: {
      image: url("../../static/img/pinTwo.svg");
      size: contain;
      position: center;
      repeat: no-repeat;
    };
  }
}

.mortarPin {
  font-size: 14px;
  line-height: 17px;
  color: #62686E;
  display: inline-block;
  position: relative;
  padding-left: 30px;
  padding-top: 3px;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 16px;
    height: 15px;
    transform: translateY(-50%);
    background: {
      image: url("../../static/img/mortarboard.svg");
      size: contain;
      position: center;
      repeat: no-repeat;
    };
  }
}
.mortarPinTwo {
  font-size: 14px;
  line-height: 17px;
  color: #62686E;
  display: inline-block;
  position: relative;
  padding-left: 30px;
  padding-top: 3px;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 16px;
    height: 15px;
    transform: translateY(-50%);
    background: {
      image: url("../../static/img/mortarboardTwo.svg");
      size: contain;
      position: center;
      repeat: no-repeat;
    };
  }
}
.check {
  font-size: 14px;
  line-height: 17px;
  color: #62686E;
  display: inline-block;
  position: relative;
  padding-left: 20px;
  padding-top: 3px;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 16px;
    height: 15px;
    transform: translateY(-50%);
    background: {
      image: url("../../static/img/check.png");
      size: contain;
      position: center;
      repeat: no-repeat;
    };
  }
}
.exp {
  font-size: 14px;
  line-height: 17px;
  color: #62686E;
  display: inline-block;
  position: relative;
  padding-left: 20px;
  padding-top: 3px;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 16px;
    height: 15px;
    transform: translateY(-50%);
    background: {
      image: url("../../static/img/exp.png");
      size: contain;
      position: center;
      repeat: no-repeat;
    };
  }
}
.verified {
  font-size: 14px;
  line-height: 17px;
  color: #62686E;
  display: inline-block;
  position: relative;
  padding-left: 20px;
  padding-top: 3px;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 16px;
    height: 15px;
    transform: translateY(-50%);
    background: {
      image: url("../../static/img/verified.png");
      size: contain;
      position: center;
      repeat: no-repeat;
    };
  }
}
